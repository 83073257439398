import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class PromotionsFechtAPI {

    static async createPromotion(data, token) {
        const res = await server(`/promotion`, {
            method: "post",
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data', "x-access-token": token
            }
        });
        return res
    }

    static async getPromotionsByBusiness(business_id, token) {
        const res = await server(`/promotion/${business_id}/business`, {
            method: "GET",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    static async getPromotionsByBranchoffice(branchoffice_id, token) {
        const res = await server(`/promotion_per_branchoffice/${branchoffice_id}/branchoffice`, {
            method: "GET",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    static async getPromotionsByid(promotion_id, token) {
        const res = await server(`/promotion/${promotion_id}`, {
            method: "GET",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    static async updatePromotionStatus(promotion_id, data, token) {
        const res = await server(`/promotion/${promotion_id}`,
            {
                method: "put",
                data: {
                    "status": data.status,
                },
                headers: { 'x-access-token': token },
            });
        return res
    }

    static async updatePromotionBranchoffice(branchoffice_id, data, token) {
        const res = await server(`/promotion_per_branchoffice/${branchoffice_id}/branchoffice`,
            {
                method: "put",
                data: data,
                headers: { 'x-access-token': token },
            });
        return res
    }

    static async updatePromotion(promotion_id, data, token) {
        const res = await server(`/promotion/${promotion_id}`,
            {
                method: "put",
                data: data,
                headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token },
            });
        return res
    }

    static async deletePromotionByBusiness(business_id, data, token) {
        const res = await server(`/promotion/${business_id}/business`,
            {
                method: "delete",
                data: data,
                headers: { 'x-access-token': token },
            });
        return res
    }

    static async deletePromotionByBrachoffice(branchoffice_id, data, token) {
        const res = await server(`/promotion_per_branchoffice/${branchoffice_id}/branchoffice`,
            {
                method: "delete",
                data: data,
                headers: { 'x-access-token': token },
            });
        return res
    }

    static async deleteSegmentByPromotion(promotion_id, segment_id, token) {
        const res = await server(`/promotion_per_segment/${promotion_id}/promotion/${segment_id}/segment`,
            {
                method: "delete",
                headers: { 'x-access-token': token },
            });
        return res
    }

    static async asingItemToSegmentDefault(promotion_id, segment_id, data, token) {
        const res = await server(`/default/item/promotion_per_segment/${promotion_id}/promotion/${segment_id}/segment`,
            {
                method: "post",
                data: data,
                headers: { 'x-access-token': token },
            });
        return res
    }
    //Function to remove default item of a segment asigned on a promotion
    static async removeDefaultItemTosegmentOnPromotion(segment_id, promotion_id, data, token) {
        const res = await server(`/default/item/promotion_per_segment/${promotion_id}/promotion/${segment_id}/segment`, {
            method: "delete",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }


    static async asingItemToSegmenttoPromotion(promotion_id, segment_id, token) {
        const res = await server(`/promotion_per_segment/${promotion_id}/promotion/${segment_id}/segment`,
            {
                method: "post",
                headers: { 'x-access-token': token },
            });
        return res
    }


    static async asingPromotionToBranchoffice(branchoffice_id, data, token) {
        const res = await server(`/promotion_per_branchoffice/${branchoffice_id}/branchoffice`,
            {
                method: "post",
                data: data,
                headers: { 'x-access-token': token },
            });
        return res
    }







}