import React, { useState, useEffect } from 'react';
import { Modal, Button, ListGroup, Row, Col, Container, Tab, Nav } from 'react-bootstrap';
import ErrorAlert from '../../Alerts/ErrorAlert';
import { GroupsFechtAPI } from '../../../api/Groups';
import { CombosFetchAPI } from '../../../api/Combos';
import Select from 'react-select';
import eye from '../../img/icons/eye.png';

// Modal details category 
function ModalCategoryListProducts({ category }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [groups, setGroups] = useState([]);
    const [combos, setCombos] = useState([]);
    const [groupsSelectAsing, setGroupsSelectAsing] = useState([]);
    const [combosSelectAsing, setCombosSelectAsing] = useState([]);
    const [showModalAsing, setShowModalAsing] = useState(false);
    const [showModalAsingCombo, setShowModalAsingCombo] = useState(false);
    const [groupSelectNow, setGroupSelectNow] = useState("")
    const [comboSelectNow, setComboSelectNow] = useState("")

    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');


    //Function to get the groups by category
    const getgroupsByCategory = async () => {
        try {
            const response = await GroupsFechtAPI.getGroupByIdCategory(category.category_id, token)
            setGroups(response.data.data)
        } catch (err) {
            setGroups([])
            console.log(err)
        }
    }
    //Function to get the combos by category
    const getCombosByCategory = async () => {
        try {
            const response = await CombosFetchAPI.getComboByCategory(category.category_id, token)
            setCombos(response.data.combos)
        } catch (err) {
            setCombos([])
        }
    }
    //We get the groups of the business
    const getGroups = async () => {

        user = JSON.parse(localStorage.getItem('user'));
        try {
            const response = await GroupsFechtAPI.getGroupsByBusiness(user.business_id, token)
            //setGroupsSelectAsing(response.data.data)

            let groupInfo = []
            response.data.data.forEach(group => {
                groupInfo.push({ value: group.group_id, label: group.name })
            });

            setGroupsSelectAsing(groupInfo)

        } catch (err) {
            console.log(err)    
            setGroupsSelectAsing([])
        }
    }

    //We get the combos of the business
    const getCombos = async () => {
            user = JSON.parse(localStorage.getItem('user'));
            try {
                const response = await CombosFetchAPI.getCombosByBusiness(user.business_id, token)
                let comboInfo = []
                response.data.combos.forEach(combo => {
                    comboInfo.push({ value: combo.id, label: combo.name })
                });
    
                setCombosSelectAsing(comboInfo)
    
            } catch (err) {
                console.log(err)
                setCombosSelectAsing([])
            }
        }
    

    const deleteCategoryToProduct = async (group_id) => {
        let data = {
            group_id: group_id
        }
        try {
            const response = await GroupsFechtAPI.deleteCategoryProduct(category.category_id, data, token)
            console.log(response);
            getgroupsByCategory();
        } catch (err) {
            console.log(err);
            ErrorAlert('', 'Error, no se pudo remover el producto', 'error')
        }
    }

    //Function to remove a combo from a category
    const removeComboFromCategory = async (combo_id) => {
        let data = {
            combos: [{ combo_id: combo_id }]
        }
        try {
            const response = await CombosFetchAPI.removeComboFromCategory(category.category_id, data, token)
            console.log(response);
            getCombosByCategory();
        } catch (err) {
            console.log(err);
            ErrorAlert('', 'Error, no se pudo remover el combo', 'error')
        }
    }

    //Function to asing product to category
    const asingProductToCategory = async () => {
        try {
            const response = await GroupsFechtAPI.asingproductToCategory(category.category_id, groupSelectNow, token)
            getgroupsByCategory()
            setShowModalAsing(false)
            ErrorAlert('', 'Producto asignado correctamente', 'success')

        } catch (err) {
            console.log(err)
            ErrorAlert('', 'Error de asignacion', 'error')
        }
    }
    //Function to asing combo to category
    const asingComboToCategory = async () => {
        try {
            let data = {
                combos: [{ combo_id: comboSelectNow }]
            }
            const response = await CombosFetchAPI.addCombosToCategory(category.category_id, data, token)
            getCombosByCategory()
            setShowModalAsingCombo(false)
            ErrorAlert('', 'Combo asignado correctamente', 'success')

        } catch (err) {
            console.log(err)
            ErrorAlert('', 'Error de asignacion', 'error')
        }
    }
    //View the products of the category
    const viewProduct = () => {

        const list = groups.map(product => (
            <Col  >
                <ListGroup as="ol" className='m-1' >
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <img
                            className='imgitemsList m-1'
                            src={product.img}
                            alt={product.name}
                        />
                        <div className="ms-2 me-auto d-inline-block text-truncate">
                            <div className="fw-bold">{product.name}</div>
                            <div className="">Precio: <b>${parseFloat(product.price).toFixed(2)}</b></div>
                            <div className="">Impuesto: <b>{product.taxes.map((tax) => (
                                <>
                                    - {tax.tax} -
                                </>
                            ))}</b></div>
                            <p className=''>{product.description}</p>
                        </div>
                        <div className='m-1'>
                            <button className='btn btn-outline-danger btn-sm border-0'
                                onClick={async () => deleteCategoryToProduct(product.group_id)}>
                                <i className="uil uil-trash-alt"></i>
                            </button>
                        </div>


                    </ListGroup.Item>
                </ListGroup>
            </Col>


        ))
        return (list)
    }

    //Function to view the combos of the category
    const viewCombos = () => {
        const list = combos.map(combo => (
            <Col>
                <ListGroup as="ol" className='m-1' >
                    <ListGroup.Item as="li"
                        className="d-flex justify-content-between align-items-start">
                        <img
                            className='imgitemsList m-1'
                            src={combo.img}
                            alt={combo.name}
                        />
                        <div className="ms-2 me-auto d-inline-block text-truncate">
                            <div className="fw-bold">{combo.name}</div>
                            <p className=''>{combo.description}</p>

                        </div>
                        <div className='m-1'>
                            <button className='btn btn-outline-danger btn-sm border-0'
                                onClick={async () => removeComboFromCategory(combo.combo_id)}
                            >
                                <i className="uil uil-trash-alt"></i>
                            </button>
                        </div>

                    </ListGroup.Item>
                </ListGroup>
            </Col>
        ))
        return (list)
    }

    const handleSaveTableSelect = (option) => {
        setGroupSelectNow(option.value);
    }

    const handleSaveTableSelectCombo = (option) => {
        setComboSelectNow(option.value);
    }

    return (
        <>
            <button className='btn btn btn-outline-light  pulse rounded-3 p-2 lh-1 border'
                onClick={() => (setShowModal(true), getgroupsByCategory(), getCombosByCategory(), getGroups(), getCombos())}
            >
                <img className='icon-options-cards' src={eye} alt="restart" />

            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='xl'
                centered>
                <Modal.Header className='text-center'>
                    <h3><i className="uil uil-eye"></i>Detalles de la categoria </h3>
                </Modal.Header>
                <Modal.Body>

                    <ListGroup as="ol" >
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <img
                                className='imgitemsList m-1'
                                src={category.img}
                                alt={category.category_name}
                            />
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{category.category_name}</div>
                                {category.secundaryName}
                            </div>
                        </ListGroup.Item>
                    </ListGroup>

                    <Tab.Container id="list-categorys-tabs-example" defaultActiveKey="categoryProducts">
                        <Row>


                        </Row>
                        <Col sm={12}>
                            <Nav variant="underline" className="d-flex horizontal-scroll border-bottom  pe-3 ">
                                <li className="nav-sp-item">
                                    <Nav.Link eventKey="categoryProducts">Productos de la categoria</Nav.Link>
                                </li>
                                <li className="nav-sp-item"  >
                                    <Nav.Link eventKey="categoryCombos" > Combos de la categoria</Nav.Link>
                                </li>
                            </Nav>
                        </Col>

                        <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey={"categoryProducts"}>
                                    <h5 className='fs-4 fw-bold m-2 text-center'>Productos de la categoria</h5>

                                    <div className='d-flex justify-content-center align-item-center'>
                                        <button className='btn btn-primary btn-sm border-0' onClick={() => (setShowModalAsing(true))}> <i className="uil uil-plus"></i> Asignar producto
                                        </button>
                                    </div>

                                    <div className="mt-3">
                                        <Container>
                                            <Row>
                                                {viewProduct()}
                                            </Row>
                                        </Container>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                            <Tab.Content>
                                <Tab.Pane eventKey={"categoryCombos"}>
                                    <h5 className='fs-4 fw-bold m-2 text-center'>Combos de la categoria</h5>

                                    <div className='d-flex justify-content-center align-item-center'>
                                        <button className='btn btn-primary btn-sm border-0' onClick={() => (setShowModalAsingCombo(true))}> <i className="uil uil-plus"></i> Asignar combos
                                        </button>
                                    </div>

                                    <div className="mt-3">
                                        <Container>
                                            <Row>
                                                {viewCombos()}
                                            </Row>
                                        </Container>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Tab.Container>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >
            {/** Modal to asing product to category */}
            <Modal
                show={showModalAsing}
                onHide={() => (setShowModalAsing(false))}
                size='large'
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-plus"></i>Asignar productos</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-center align-item-center'>


                        <Select
                            name="user_branch" id="user_branch"
                            className='w-100 m-3'
                            options={groupsSelectAsing}
                            onChange={handleSaveTableSelect}
                        />
                        <Button size="sm" variant="success" type="button" className='m-3' onClick={async () => asingProductToCategory()}>
                            Guardar
                        </Button>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModalAsing(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
            {/** Modal to asing combo to category */}
            <Modal
                show={showModalAsingCombo}
                onHide={() => (setShowModalAsingCombo(false))}
                size='large'
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-plus"></i>Asignar combos</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-center align-item-center'>


                        <Select
                            name="user_branch" id="user_branch"
                            className='w-100 m-3'
                            options={combosSelectAsing}
                            onChange={handleSaveTableSelectCombo}
                        />
                        <Button size="sm" variant="success" type="button" className='m-3' onClick={async () => asingComboToCategory()}>
                            Guardar
                        </Button>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModalAsingCombo(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default ModalCategoryListProducts