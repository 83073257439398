import React, { useState, useEffect } from 'react'

import { Modal, Form, Button } from 'react-bootstrap';
//Api
import { GroupsFechtAPI } from '../../../api/Groups';
import { OracleMicrosFetchAPI } from '../../../api/OracleMicros';
//Alerts
import SuccessAlert from '../../Alerts/SuccessAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';
import LoadingAlert from '../../Alerts/LoadingAlert';

//Utils 
import Socket from '../../utils/Socket.config';
//Modules 
import pako from 'pako';
import { Buffer } from 'buffer';

export default function Micros({ business_code, branchoffice, token, pos_integration_id }) {

    const [socket, setSocket] = useState(null);
    const [eventhandler, setEventHandler] = useState(null);
    
    
    //Function to syncronice oracle micros params 
    const syncroniceOracleMenuMicros = async (microsMenu) => {
        try {
            //We format the response of the micros menu 
            let products = [];
            let categories = [];
            let segments = [];
            let items = [];

            // Function to add the products and categories
            const addProductAndCategories = (productId, productName, productPrices, productImages, segmentRefs, categoryInfo) => {
                let productPrice = 0;
                let productTax = 0;
                //We checkc if the product has more than one price 
                if (productPrices.length > 1) {
                    let index = productPrices.findIndex(price => price.name === 'Default' || price.name === 'SM');
                    //If we dont find any product we just add the first price 
                    if (index === undefined || index === -1) {
                        productPrice = productPrices[0].price;
                    } else {
                        productPrice = productPrices[index].price
                    }
                } else {
                    productPrice = productPrices[0].price
                }
                // Almacenar el producto en el array de productos con su ID
                products.push({ id: productId, productImages: productImages === undefined ? [] : productImages, preparation: 0, external_reference: productId, segmentsRefs: segmentRefs === undefined ? [] : segmentRefs, description: productName, name: productName, price: productPrice, pos_integration_id: pos_integration_id, taxes: [{ tax_id: 4 }] });

                // Iterar sobre los IDs de categorías y almacenarlas en el array de categorías
                categoryInfo.forEach(category => {
                    let index = categories.findIndex(cat => cat.id === category.id)
                    // Check of coincidences of the categories
                    if (index === -1) {
                        categories.push({ name: category.name, description: category.name, id: category.id, category_type: 1, categoryImages: [], external_reference: category.id, productsReference: [productId] });
                    } else {
                        //If the category is on the array we get if the reference of the product is on the category
                        let referenceCoincidence = categories[index].productsReference.some(pr => pr === productId);
                        //If the product is not on the category we insert it 
                        if (!referenceCoincidence) {
                            categories[index].productsReference.push(productId)
                        }
                    }
                });
            };


            //Menu items
            microsMenu.menuItems.forEach(element => {
                // We check if the menuItem has any definition 
                if (element.definitions) {
                    // Verificar si al menos una definición tiene slus
                    if (element.definitions.some(definition => definition.slus)) {
                        // We get the product name 
                        const productName = element.name["en-US"];
                        const productId = element.menuItemId;
                        //We get the prices of the product 
                        const productPrices = Array.from(
                            new Set(element.definitions
                                .filter(definition => definition.prices)
                                .flatMap(definition => definition.prices.map(price => ({ price: price.price, name: price.name }))))
                        );

                        //We get the segmentRefs of the product 
                        const segmentsRefs = Array.from(
                            new Set(element.definitions
                                .filter(definition => definition.condimentGroupRules)
                                .flatMap(definition => definition.condimentGroupRules.map(segment => (segment.condimentGroupRef))))
                        )

                        //We get the product img of the product 
                        const productImages = Array.from(
                            new Set(element.definitions
                                .filter(definition => definition.images)
                                .flatMap(definition => definition.images.map(image => ({
                                    url: image.url,
                                    altText: image.altText,
                                    description: image.description,
                                    size: image.size
                                }))))
                        )

                        // We get the categories of the products 
                        const categorys = Array.from(
                            new Set(
                                element.definitions
                                    .filter(definition => definition.slus)
                                    .flatMap(definition => definition.slus.map(slu => ({ id: slu.sluId, name: slu.name["en-US"] })))
                            )
                        );
                        //We call the function to add the products and categories 
                        addProductAndCategories(productId, productName, productPrices, productImages, segmentsRefs, categorys);
                    }
                }
            });

            //Individual items 
            microsMenu.condimentItems.forEach(element => {
                const itemName = element.name['en-US'];
                const itemId = element.condimentId;
                const itemPrices = Array.from(
                    new Set(element.definitions
                        .filter(definition => definition.prices)
                        .flatMap(definition => definition.prices.map(price => ({ price: price.price, name: price.name })))
                    )
                )
                let itemPrice = 0;
                //We checkc if the item has more than one price 
                if (itemPrices.length > 1) {
                    let index = itemPrices.find(price => price.name === 'Default');

                    //If we dont find any product we just add the first price 
                    if (index === undefined) {
                        itemPrice = itemPrices[0].price;
                    } else {
                        itemPrice = index.price
                    }
                } else {
                    itemPrice = itemPrices[0].price
                }

                //We get the images of the items 
                const itemImages = Array.from(
                    new Set(element.definitions
                        .filter(definition => definition.images)
                        .flatMap(definition => definition.images.map(image => ({
                            url: image.url,
                            altText: image.altText,
                            description: image.description,
                            size: image.size
                        }))))
                )

                //We get put the information 
                items.push({ name: itemName, id: itemId, itemImages: itemImages === undefined ? [] : itemImages, price: itemPrice, item_type: "infinite", item_preparation: 0, description: itemName, external_reference: itemId, pos_integration_id: pos_integration_id });
            })

            //Segments 
            microsMenu.condimentGroups.forEach(element => {
                const segmentName = element.name["en-US"];
                const segmentId = element.condimentGroupId;
                const itemsRefs = element.condimentItemRefs;
                segments.push({ name: segmentName, description: segmentName, id: segmentId, itemsRefs: itemsRefs, external_reference: segmentId, pos_integration_id: pos_integration_id })
            });

            console.log("estamos por insertar>>",branchoffice.id, products, items, segments, categories, pos_integration_id, token)
            //Request to insert menu on bulk 
            const result = await GroupsFechtAPI.insertMenuOnBulk(branchoffice.id, products, items, segments, categories, pos_integration_id, token)
            //Success message 
            SuccessAlert(result.data.message);
        } catch (err) {
            console.log(err)
            //Error alert 
            ErrorAlert(err.code ? err.code : "Error", err.code === 'ERR_NETWORK' ? 'Servidor no disponible' : 'Error de conexion', 'error');
        }
    }

    //Function to get the oracle micros params 
    const getOracleMicrosParams = async () => {
        try {
            //We get the micros menu params 
            const res = await OracleMicrosFetchAPI.getMicrosMenuParams(branchoffice.id, token);
            //We format the IP menu micros 
            let host_url = res.data.params.host_url
            let employeeNum = res.data.params.employeeNum
            let rvcRef = res.data.params.RvcRef;
            return { host_url, employeeNum, rvcRef }
        } catch (err) {
            return null
        }
    }

    //Hook to conect the client to the sockets and make the initial charge of data 
    useEffect(() => {
        // Connection with the sockets server
        const newsocket = Socket({ "business_code": business_code, micros_local_server: true });

        //We listen the event of the socket 
        setSocket(newsocket)

        //We get the menu 
        newsocket.on(`micros_${business_code}_chargeMenuData`, ({data}) => {
            console.log("aqui el menu>>", data)
            try {
                const compressedBuffer = Buffer.from(data, 'base64');
                const decompressedData = JSON.parse(pako.inflate(compressedBuffer, { to: 'string' }));
                console.log('Decompressed data:', decompressedData);
                //We insert the data 
                syncroniceOracleMenuMicros(decompressedData)
            } catch (error) {
                console.error('Error decompressing data:', error);
            }
        })
        return () => {
            newsocket.disconnect();
        }
    }, [business_code]);

    //Hook to emit the event of the socket
    useEffect(() => {

        //We emit the event of the socket
        if (eventhandler === 'getMenu' && socket !== null) {
            console.log("si entre")
            async function fetchData() {
                //We call the function 
                const response = await getOracleMicrosParams();
                console.log(response)
                //We check the response 
                if (response !== null) {
                    //We emit the info 
                    socket.emit(`microsMenu_${business_code}`, { host_url: response.host_url, employeeNum: response.employeeNum, RvcRef: response.rvcRef })
                }
            }
            fetchData();
        }

        //We set the eventhandler to null
        setEventHandler(null)
    }, [eventhandler]);

    return (
        <div>
            {/** Button to syncronice menu */}
            <div className=" d-flex justify-content-center justify-content-sm-end mb-3">
                {/*                 <button type="button" onClick={() => (LoadingAlert('Sincronizando menu...', 'Espere por favor...'), syncroniceOracleMenuMicros())} className="btn btn-success px-5">Sincronizar menu</button>
 */}
                <button type="button" onClick={() => (setEventHandler("getMenu"), console.log("si click"))} className="btn btn-success px-5">Sincronizar menu</button>

            </div>
        </div>
    )
}
